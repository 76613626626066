/* eslint-disable no-param-reassign */
const getInitialState = () => ({ curriculumGoals: {} });

const curriculumSlice = (set, get) => ({
  sliceName: 'curriculum',
  getInitialState,
  selectors: {
    ...getInitialState(),
  },
  mutators: {
    setCurriculumGoals: (data) =>
      set((draft) => {
        draft.curriculumGoals = data.curriculumGoals;
      }),
  },
});

export default curriculumSlice;
